<template>
    <div class="container">
        <div class="checkin">
            <el-row :gutter="20">
                <el-col :span="5">
                    <serviceleft :activeIndex="leftPannelSelected"/>
                </el-col>
                <el-col :span="19">
                    <div class="pannel-top" style="width:100%;overflow:hidden; height:120px;"></div>
                    <div class="right_pannel">
                        <div style="width:940px; margin-top:10px;">
                            <div style="margin-left: 20px;padding-top:10px;">
                                <el-row v-if="loginType=='AIRPORT'">
                                    <el-col :span="7" class="form-group">
                                        <div class="filter">
                                            <p class="filter-title">姓名</p>
                                            <p class="filter-value">{{userInput.name}}</p>
                                        </div>
                                    </el-col>
                                    <el-col :span="7" class="form-group">
                                        <div class="filter">
                                            <p class="filter-title">电话电话</p>
                                            <p class="filter-value">{{userInput.phone}}</p>
                                        </div>
                                    </el-col>
                                    <el-col :span="7" class="form-group">
                                        <div class="filter">
                                            <p class="filter-title">凭证号码</p>
                                            <p class="filter-value">{{userInput.cert}}</p>
                                        </div>
                                    </el-col>
                                    <!-- <el-col :span="3" class="form-group">
                                        <el-button
                                            type="danger"
                                            @click="gotoPage('checkin')"
                                            style="width:100px;border-radius: 20px;"
                                        >重新填写</el-button>
                                    </el-col> -->
                                </el-row>
                            </div>
                        </div>
                        <div class="flightList" v-loading="loadingShow">
                            <!-- @errorEvent="errorEvent" @loadingEvent="loadingEvent" -->
                            <journey-item
                                v-for="(item,index) in journeyList"
                                :item="item"
                                v-bind:key="index"
                                @errorEvent="errorEvent"
                                @loadingEvent="loadingEvent"
                            ></journey-item>
                        </div>
                        <!-- <alert
                :show="error.message != ''"
                :buttonText="error.buttonText"
                @on-hide="error.callback"
                :html="error.message">
                        </alert>-->
                        <!-- <loading :show="loadingShow" text="数据获取中"></loading> -->
                        <!-- <div style="margin-top:110px;height:200px">
                            <div class="warm-prompt">
                                <h3>温馨提示</h3>
                                <div>
                                    <p>1.预计起飞时间仅作为参考，如发生临时变动，请以现场通知为准；</p>
                                    <p>2.航班状态不作为非自愿退改签依据，具体情况请联系原购票地咨询；</p>
                                    <p>3.登机口可能会有更改，请在到达机场后查看航班动态显示屏或询问湖南航空柜台人员确定登机口。</p>
                                </div>
                            </div>
                        </div>-->
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import serviceleft from "../../components/ServiceLeft";
import JourneyItem from "../../components/checkin/journeyItem/index.vue";
import { fetchCheckinFlightList, cancelCheckin } from "@/api/newsapi";
export default {
  components: { serviceleft, JourneyItem },
  data() {
    return {
      leftPannelSelected: "li_checkin",
      input: "",
      loginType: "AIRPORT",
      timeDefaultShow: new Date(),
      scheduleLoading: false,
      dynamicFilter: {
        dynamicType: "AIRPORT",
        deptAirportCode: "",
        deptAirport: "",
        arrAirport: "",
        arrAirportCode: "",
        deptDate: "",
        flightNo: ""
      },
      restaurants: [],
      tableData: [],
      userInput: {},
      journeyList: [],
      error: {
        message: "",
        callback: function() {
          this.error.message = "";
        }.bind(this),
        buttonText: "知道了"
      },
      loadingShow: false // loading开关
    };
  },
  mounted() {
    this.userInput = JSON.parse(
      localStorage.getItem("airtravel-checkin-userInput")
    );
    this.journeyList = JSON.parse(
      localStorage.getItem("airtravel-checkin-list")
    );
    // 把去程放在数组第一个
    for (let i = 0; i < this.journeyList.length; i++) {
      this.journeyList[i].tours.sort((a, b) => {
        return parseInt(a.tourIndex) - parseInt(b.tourIndex);
      });
    }
  },
  methods: {
    /**
     * 用于接受子组件alert事件
     */
    errorEvent(str) {
      this.$alert(str, "温馨提示", {
        confirmButtonText: "知道了"
      });
    },
    loadingEvent(val) {
      this.loadingShow = val;
    },
    gotoPage(route) {
      this.$router.push({ name: route });
    },
    selectPannel(pannelId) {
      // console.log(pannelId);
      document.getElementById("li_booking").setAttribute("class", "");
      document.getElementById("li_aoc").setAttribute("class", "");
      document.getElementById("li_checkin").setAttribute("class", "");
      document.getElementById("li_order").setAttribute("class", "");
      document.getElementById("li_xl").setAttribute("class", "");
      document.getElementById(pannelId).setAttribute("class", "active");
    },
    checkInAction() {
      this.$alert("系统维护，请稍后在试", "温馨提示", {
        confirmButtonText: "已了解",
        callback: action => {
          // this.$message({
          //   type: 'info',
          //   message: `action: ${ action }`
          // });
        }
      });
    }
  }
};
</script>

<style scoped lang="less">
.checkin {
  margin-top: 20px;
  margin-bottom: 20px;
}

.left_pannel {
  height: 500px;
  background-color: #f7f7f7;
  overflow: hidden;
}

.right_pannel {
  min-height: 700px;
  position: relative;
  /* background-color: #f7f7f7;
      border: 1px solid #ededed; */
}

.panel-tabs .active {
  /* background-color: #d6003a; */
  /* color:#FFFFFF */
}

.panel-tabs .active a {
  /* color:#FFFFFF */
}

.col_menu b {
  font-size: 24px;
  margin: 20px 20px 0;
  color: #333;
  display: block;
  font-weight: normal;
  border-bottom: 1px solid #f1f1f1;
}

.col_menu ul {
  margin-bottom: 20px;
}

ul,
ol,
dl,
dd,
dt {
  margin: 0;
  padding: 0;
  display: block;
}

.col_menu li {
  margin: 10px 0;
  position: relative;
}

.col_menu li.current {
  background: none;
}

.col_menu li.current a:link,
.col_menu li.current a:visited,
.col_menu li.current a:active {
  border-left: 2px #ff6600 solid;
  color: #666;
  font-weight: bold;
}

.col_menu li a:link,
.col_menu li a:visited,
.col_menu li a:active {
  display: block;
  line-height: 30px;
  padding: 0 55px 0 20px;
}

a:link,
a:visited,
a:active {
  color: #666;
  text-decoration: none;
}

a {
  color: #337ab7;
  text-decoration: none;
}

.warm-prompt {
  padding: 40px 50px 80px;
}

.warm-prompt p {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #9d9d9d;
  text-align: left;
}

.service-query {
  position: relative;
  z-index: 10;
  margin: 0 auto;
  padding: 8px 44px 0;
  width: 578px;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  /* -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.05);
      box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.05); */
}

.service-query .service-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 55px;
  color: #4d4e4e;
  text-align: center;
  border-bottom: 1px solid #eae7e7;
}

.service-query .service-prompt {
  padding-top: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.8;
  color: #9d9d9d;
  text-align: center;
}

.service-query .service-forms > li {
  margin-top: 20px;
}

.form-group .form-label {
  width: 100px;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 35px;
  color: #4d4e4e;
  text-align: right;
}

.checkin-links.query {
  margin: 24px 35px 24px 75px !important;
}

.agree-protocol {
  margin: 24px 0;
  padding-left: 24px;
  font-size: 0;
}

.link.link-sm {
  height: 18px;
  font-size: 12px;
  line-height: 18px;
}

.link {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #2c6e8b;
  text-align: left;
}

.search-block {
  width: 1200px;
  margin: auto;
  position: relative;
}

.popcontext .tab {
  z-index: 1000;
  width: 240px;
}

.popcontext .tab li {
  height: 60px;
  line-height: 60px;
  /* border-top: 1px solid #4e617f; */
  background-color: #ffffff !important;
  color: #000000;
  font-size: 14px;
  text-align: center;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  cursor: pointer;
  margin-left: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  filter: alpha(Opacity=60);
  -moz-opacity: 0.6;
  opacity: 0.6;
}

/* border-bottom: 1px solid #d6003a; */
.popcontext .tab li:nth-child(2) {
  border-top: 1px solid #d6003a;
}

.popcontext .tab li:last-child {
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-bottom-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.popcontext .tab li:nth-child(2).active {
  border-top: 1px solid #f9f9f9;
}

.popcontext .tab li.active {
  /* border-top: 1px solid #fff; */
  background-color: #d6003a;
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  margin-left: 0;
  padding-left: 10px;
  border-right: 1px solid #fff;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  filter: alpha(Opacity=100);
  -moz-opacity: 1;
  opacity: 1;
}

.popcontext hgroup {
  display: none;
  position: absolute;
  width: 460px;
  top: -410px;
  left: 0px;
  z-index: 1000;
  margin-left: 140px;
  background-color: #fff;
  -webkit-border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  border-radius: 0 5px 5px 0;
}

.popcontext hgroup article {
  position: relative;
  height: 364px;
  padding-left: 30px;
}

.panel-header {
  /* border-bottom: 2px solid #e9e8e8; */

  width: 400px;
}

.panel-header .panel-tabs {
  display: block;
  width: 100%;
  height: 42px;
  margin: 0;
  padding: 0;
}

.panel-tabs li {
  width: 50%;
  position: relative;
  float: left;
  /* background-color: #EEEEEEFF; */
}

.panel-tabs li:first-child {
  border-radius: 10px 0px 0 0;
}

.panel-tabs li:last-child {
  /* border-radius: 0px 10px 0 0; */
}

.panel-tabs li a {
  position: relative;
  display: block;
  margin: auto;
  width: 80px;
  height: 42px;
  font-size: 14px;
  font-weight: 400;
  line-height: 42px;
  text-align: center;
  cursor: pointer;
}

.panel-tabs .clearfix:after {
  display: block;
  height: 0;
  content: "";
  clear: both;
  overflow: hidden;
}

.panel-tabs .active {
  background-color: #d6003a;
  color: #ffffff;
}

.panel-tabs .active a {
  color: #ffffff;
}

.filter {
  width: 100%;
  float: left;
}

.filter .el-input {
  /* margin-top: 10px; */
}

.el-date-editor .el-input {
  width: 100%;
}

/deep/.filter-title {
  color: #929a9f;
  font-size: 14px;
  width: 70px;
  display: inline-block;
}
/deep/.filter-value {
  color: #515457;
  font-size: 14px;
  display: inline-block;
}

.filter-input {
  position: relative;
  padding-top: 10px;
}

.filter-input .el-input input {
  height: 45px !important;
  line-height: 45px;
}

.filter-panel {
  background-color: #ffffff;
  position: absolute;
  top: 45px;
  left: 0;
  width: 424px;
  padding: 8px 16px 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
}

.filter-panel-title {
  color: #9d9d9d;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-select-dropdown__item {
  padding-left: 15px;
}

.separet {
  height: 14px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  margin: 0 9px;
  margin-top: 50px;
}

.search-btn {
  width: 80px;
  height: 35px;
  margin-top: 20px;
  margin-left: 32px;
  background-color: #ff0505;
  color: #ffffff;
  border: 0;
  font-size: 14px;
  font-weight: 700;
}

.el-tabs__active-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: #f7214b;
  z-index: 1;
  -webkit-transition: -webkit-transform 0.3s
    cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  list-style: none;
}

.panel-tabs li.active:after {
  position: absolute;
  right: 0;
  bottom: -2px;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  content: "";
  background-color: #ce212e;
}

.el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 45px;
  line-height: 45px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}

.aoctable .el-table__header-wrapper table thead tr {
  background-color: #cccccc;
}
</style>
<style lang="less" scoped>
.passengerInfo {
  height: 66px;

  .name {
    font-size: 16px;
    color: #333;
    margin-bottom: 2px;
  }

  .cert {
    font-size: 12px;
    color: #666;
  }
}

.flightList {
  margin-top: 10px;
  border-top: 2px solid #f6f6f6;
}
.pannel-top {
  height: 150px;
  background: url("../../assets/aoc_top_bg.png");
  border-radius: 10px;
}
</style>